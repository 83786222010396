<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="danger"
          class="btn-sm"
          @click="modalTypeQuestion = true"
        >
          {{ $t('Add') }}
        </b-button>
      </div>
    </b-col>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="1">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col md="4">
            <label for="">
              Tag
            </label>
            <vue-autosuggest
              :suggestions="filteredOptions"
              :limit="30"
              :input-props="inputProps"
              @selected="onSelected"
              @input="onInputChange"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </b-col>
          <b-col md="3">
            <label for="">
              Loại câu hỏi
            </label>
            <b-form-select
              v-model="filter.type"
              :options="typeOptions"
              @change="loadItems"
            />
          </b-col>
          <b-col md="4">
            <label for="">
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      v-model="cbxAll"
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                    scope="col"
                    width="150"
                  >
                    Loại
                  </th>
                  <th
                    scope="col"
                    width="50"
                  >
                    Tag
                  </th>
                  <th
                    width="50"
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    @click="changeSortDirection('created_at')"
                  >
                    {{ $t('crt') }}
                  </th>
                  <th width="10">
                    {{ $t("opiton") }}
                  </th>
                </tr>
              </thead>
              <template v-if="rows.length">
                <template v-if="isLoading">
                  <tr
                    v-for="(item) in 3"
                  >
                    <td colspan="1">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="3">
                      <b-skeleton animation="fade" />
                    </td>
                    <td colspan="3">
                      <b-skeleton animation="fade" />
                    </td>
                  </tr>
                </template>
                <tr
                  v-for="(item, index) in rows"
                  v-else
                  :id="item.id"
                  :key="item.id"
                >
                  <td>
                    <b-form-checkbox
                      v-model="ids"
                      :value="item.id"
                      class="custom-control-danger"
                      name="check-button"
                    />
                  </td>
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <router-link
                      :to="{ name: typeQuestion.find(val => val.type === item.type).to, params: { data: item } }"
                    >
                      <template v-if="item.type === 4">
                        <span
                          v-for="q in JSON.parse(item.name)"
                          style="display: flex"
                          v-html="q.q + ' <strong>(==)</strong> ' + q.ans"
                        />
                      </template>
                      <template v-else-if="item.type === 3">
                        <span
                          v-for="q in JSON.parse(item.name)"
                          v-html="q.q + ' <strong>,</strong> '"
                        />
                      </template>
                      <span
                        v-else
                        v-html="item.name"
                      />
                    </router-link>
                  </td>
                  <td>
                    {{ typeQuestion.find(val => val.type === item.type).text }}
                  </td>
                  <td>
                    <div
                      v-if="item.tags"
                      class="demo-inline-spacing"
                    >
                      <b-badge
                        v-for="(tag, index) in item.tags"
                        :id="index"
                        variant="light-danger"
                      >
                        {{ tag.name }}
                      </b-badge>
                    </div>
                  </td>
                  <td>{{ convertDate(item.created_at) }}</td>
                  <td>
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item :to="{ name: typeQuestion.find(val => val.type === item.type).to, params: { data: item } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Sửa</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="deleteMulti(item.id)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Xóa</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <tr
                v-else
              >
                <td colspan="6">
                  <p class="text-center">
                    {{ $t('no_data') }}
                  </p>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </b-card-code>
      <b-modal
        id="modal-edit"
        v-model="modalTypeQuestion"
        :ok-title="$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        size="md"
        title="Chọn loại câu hỏi"
        hide-footer
      >
        <b-row>
          <b-col
            v-for="(q) in typeQuestion"
            md="4"
            style="margin-bottom: 20px;"
          >
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: q.to}"
                style="border: 1px dashed #d7d7d7 !important;"
              >
                <feather-icon
                  v-if="q.icon"
                  :icon="q.icon"
                  class="mr-50"
                />
                {{ q.text }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </b-col>
  </b-row>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<script>
// eslint-disable-next-line no-unused-vars
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import {
  required, image, url, email, size, min_value,
} from '@validations'

import config from '@/config'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
  BTab,
  BTabs,
  BCardText, VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import Table from '@/views/table/bs-table/Table'

export default {
  components: {
    BSkeleton,
    VueAutosuggest,
    Table,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BImg,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
    BTab,
    BTabs,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Tìm kiếm tag',
      },
      titleFile: '',
      isUploadImg: true,
      cbxAll: false,
      modalShowFile: false,
      currentPage: 1,
      currentPageFile: 1,
      tagOptionByReview: [],
      filterTag: '',
      pageLength: 30,
      typeQuestion: config.type_question,
      isShow: true,
      modalTypeQuestion: false,
      dragging: false,
      // eslint-disable-next-line global-require
      required,
      image,
      url,
      email,
      size,
      min_value,
      isLoading: false,
      editorConfig: {},
      total: 0,
      totalFile: 0,
      logOptionTag: [],
      rows: [],
      ids: [],
      filteredOptions: [],
      modalName: '',
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      typeOptions: [
        {
          text: 'Tất cả',
          value: 'all',
        },
        {
          text: 'Trắc nghiệm',
          value: 1,
        },
        {
          text: 'Ghi âm',
          value: 2,
        },
        {
          text: 'Ghép từ',
          value: 3,
        },
        {
          text: 'Ghép cột',
          value: 4,
        },
        {
          text: 'Điền từ',
          value: 5,
        },
      ],
      filter: {
        key: '',
        type: 'all',
        tag: [],
        statusOptions: [
          { value: 'all', text: 'Tất cả' },
          { value: 1, text: 'Hoạt động' },
          { value: 0, text: 'Không hoạt động' },
        ],
      },
      filterFile: {
        key: '',
        type: 'all',
      },
      sort: {
        by: 'id',
        direction: 'desc',
      },
      tagOptions: [],
      dataFiles: [],
      tab: true,
      img: null,
      audio: null,

    }
  },
  watch: {
    ids: {
      handler(value) {
        this.cbxAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadItems()
    this.loadTagReviews()
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.loadTagByName(search, loading, this.$http, this)
      }
    },
    loadTagByName: _.debounce((search, loading, call, vm) => {
      call.get('/tags', {
        params: {
          perPage: 10,
          key: search,
          type: 0,
        },
      })
        .then(res => {
          vm.tagOptions = res.data.data
          if (vm.logOptionTag.length) {
            vm.logOptionTag.map(v => {
              vm.tagOptions.push(v)
            })
            vm.tagOptions = _.uniqBy(vm.tagOptions, 'id')
          }
          loading(false)
        }).catch()
        .finally(() => {
        })
    }, 250),
    uploadMedia(media) {
      this.frm.questionParents.audio = media.audio
      this.frm.questionParents.image = media.image
    },
    changeTitle(flag) {
      this.isUploadImg = flag
      this.titleFile = flag ? this.$t('choose_img') : this.$t('choose_audio')
      this.filterFile.type = flag ? 1 : 2
    },
    clearUpload(tmp) {
      tmp ? this.frm.questionParents.image = null : this.frm.questionParents.audio = null
    },
    onSelected(option) {
      const tag = option.item
      this.filter.tag.push(tag.id)
      this.loadItems()
      this.filter.tag = []
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      this.filterTag = text
      this.loadTags(this)
    },
    loadTags: _.debounce(vm => {
      vm.$http.get('/tags', {
        params: {
          perPage: vm.pageLength,
          page: vm.currentPage,
          key: vm.filterTag,
          type: 0,
        },
      })
        .then(res => {
          vm.tagOptions = res.data.data
          vm.filteredOptions = [{
            data: res.data.data,
          }]
        }).catch()
        .finally(() => {
        })
    }, 350),
    loadTagReviews() {
      this.isLoading = true
      this.$http.get('/tags', {
        params: {
          type: 1,
          limit: 100,
        },
      })
        .then(res => {
          this.tagOptionByReview = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/questions', {
            data: {
              ids: this.ids,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/questions', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          key: this.filter.key,
          status: this.filter.status,
          type: this.filter.type,
          order_by: this.sort.by,
          tag: JSON.stringify(this.filter.tag),
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    resetModal(flag, question = false) {
      this.editModal = false
      this.titleAdd = flag ? this.$t('Add') : this.$t('Edit')
      this.frm.questionParents.name = this.frm.questionParents.type = null
      this.frm.questionParents.tag = []
      this.frm.questionParents.answers = []
      this.frm.questionParents.tag_review_id = this.frm.questionParents.explain = null
      this.frm.questionParents.is_correct = false
      this.frm.questionParents.image = this.frm.questionParents.audio = null
      this.tagOptions = []
      this.logOptionTag = []
      if (question) {
        const tags = []
        this.editModal = question.id
        this.frm.questionParents.name = question.name
        this.frm.questionParents.type = question.type
        this.frm.questionParents.answers = question.answers
        this.frm.questionParents.status = question.status
        this.frm.questionParents.explain = question.explain
        this.frm.questionParents.tag_review_id = question.tag_review_id
        if (question.tags) {
          question.tags.map(v => {
            tags.push(v.id)
            this.tagOptions.push(v)
            this.logOptionTag.push(v)
          })
          this.frm.questionParents.tag = tags
          this.tagOptions = _.uniqBy(this.tagOptions, 'id')
          this.logOptionTag = _.uniqBy(this.logOptionTag, 'id')
        }
        if (question.answers) {
          question.answers.map((v, index) => {
            if (v.is_correct === 1) {
              this.frm.questionParents.is_correct = index
            }
          })
        }
        if (question.image_id) {
          this.frm.questionParents.image = question.image
        }
        if (question.audio_id) {
          this.frm.questionParents.audio = question.audio
        }
      }
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    submit() {
      this.$refs.addQuestion.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()

          // handle answer
          if (this.frm.questionParents.answers.length) {
            this.frm.questionParents.answers = this.frm.questionParents.answers.map((v, index) => {
              // eslint-disable-next-line no-param-reassign
              v.is_correct = false
              if (index === this.frm.questionParents.is_correct || this.frm.questionParents.type === 5) {
                v.is_correct = true
              }
              return v
            })
          }

          const tags = []
          const newTags = []
          if (this.frm.questionParents.tag.length) {
            this.frm.questionParents.tag.map(v => {
              v === parseInt(v) ? tags.push(v) : newTags.push({ name: v })
            })
            formData.append('tags', JSON.stringify(tags))
            formData.append('newTags', JSON.stringify(newTags))
          }
          // handle param
          formData.append('questionParents', JSON.stringify(this.frm.questionParents))
          formData.append('answers', JSON.stringify([]))

          if (this.frm.questionParents.audio) {
            formData.append('audio_id', this.frm.questionParents.audio.id)
          }
          if (this.frm.questionParents.image) {
            formData.append('image_id', this.frm.questionParents.image.id)
          }
          const url = this.editModal ? `/questions/${this.editModal}` : '/questions'
          if (this.editModal) formData.append('_method', 'PUT')

          this.$http({
            method: 'post',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.modalShow = false
              this.loadItems()
              this.resetModal()
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data.errors
              } else {
                errorMsg.name = [error.response.data.error]
              }
              this.$refs.addQuestion.setErrors(errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    addQuestion() {
      this.frm.questionChilds.push({
        name: '',
        type: null,
        answers: [],
        is_correct: false,
        status: true,
      })
    },
    removeQuestion(index) {
      this.frm.questionChilds.splice(index, 1)
    },

    addAnswer() {
      const ans = this.frm.questionParents.answers
      ans.push({
        name: '',
        is_correct: false,
      })
    },
    removeAnswer(index) {
      const ans = this.frm.questionParents.answers
      ans.splice(index, 1)
    },
  },
}
</script>
